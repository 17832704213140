<template>
  <div>
        <v-col class='pb-0' v-if="action == 'shipping'">
            <v-checkbox v-model='billingDirection' label='Es dirección de facturación.?'></v-checkbox>
        </v-col>
            <v-form ref='from_direction' >
                <v-row>
                    <v-col class='mt-6'>
                        <v-col class='py-0'>
                            <v-text-field
                                v-if="attnName!=''"
                                v-model=attnName
                                dense
                                label='Atiende en dirección'
                                prepend-inner-icon='mdi-account-alert'
                                :rules=requiredRule
                                @keyup="setShippingPoint"
                            ></v-text-field> 
                        </v-col>
                        <v-col class='py-0'>
                            <v-autocomplete
                                autofocus
                                v-model=province
                                :items=provinces
                                item-value='geo_id'
                                item-text='geo_name'
                                dense
                                label='Seleccione la provincia'
                                prepend-inner-icon='mdi-map'
                                :rules=requiredRule
                                @change="getDataAutocomplete(`${province}/parroquias-provincia`,'states')"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class='py-0'>
                            <v-autocomplete
                                v-model=state
                                :items=states
                                item-value='parroquia_id'
                                item-text='parroquia'
                                dense
                                label='Seleccione la ciudad'
                                prepend-inner-icon='mdi-city'
                                return-object
                                :rules=requiredRule
                                @change="getDataAutocomplete(`${state.canton_id}/sectores-canton`,'sector')"
                            ></v-autocomplete>
                        </v-col>
                        <v-col class='py-0'>
                            <v-autocomplete
                                v-if=autoCompleteSector
                                v-model=sector
                                :items=sectors
                                item-value='id'
                                item-text='nombre'
                                dense
                                label='Seleccione el sector'
                                prepend-inner-icon='mdi-city'
                                :rules=requiredRule
                                @change="setShippingPoint"
                            ></v-autocomplete>
                            <v-text-field
                                v-else
                                v-model=sector
                                dense
                                label='Escriba el sector'
                                prepend-inner-icon='mdi-city'
                                :rules=requiredRule
                                @keyup="setShippingPoint"
                            ></v-text-field>
                        </v-col>
                        <v-col class='py-0'>
                            <v-text-field
                                v-model=street
                                dense
                                label='Calle principal y número'
                                prepend-inner-icon='mdi-map-marker-radius'
                                @keyup="setShippingPoint"
                            ></v-text-field>
                        </v-col>
                        <v-col class='py-0'>
                            <v-text-field
                                v-model=street2
                                dense
                                label='Calle secundaria'
                                prepend-inner-icon='mdi-map-marker-radius'
                                @keyup="setShippingPoint"
                            ></v-text-field>
                        </v-col>
                        <v-col class='py-0'>
                            <v-textarea
                                v-model=reference
                                outlined
                                label='Referencias'
                            ></v-textarea>
                        </v-col>
                    </v-col>
                    <!-- <v-col md=7 >
                        <v-col class='py-0'>
                            <Map :makers=pointShippment />
                        </v-col>
                    </v-col> -->
                </v-row>
            </v-form>
            <v-col class='text-center pt-0'>
                <v-btn 
                    color='primary'
                    @click=storeDirection
                    small
                > 
                    <v-icon>mdi-content-save</v-icon> Guardar 
                </v-btn>
                <v-btn 
                    color='default'
                    @click=close
                    small
                > 
                    <v-icon>mdi-cancel</v-icon> Cerrar 
                </v-btn>
        </v-col>
    </div>
</template>

<script>

var interval=""

import {mapState, mapMutations, mapActions} from 'vuex'
import Map from '../general/Map'

export default {
    name: 'CaptureDirection',
    components:{
        Map
    },
    props:{
        action:{
            type:String,
            default:''
        },
        attnName:{
            type: String,
            default:''
        }

    },
    data: () => ({
        saveDirection:false,
        street:null,
        street2:null,
        direction:null,
        billingDirection:true,
        province:null,
        state:null,
        sector:null,
        reference:null,
        autoCompleteSector: true,
        provinces:[],
        states:[],
        sectors:[],
        pointShippment:[],
    }),
    computed:{

        ...mapState('pos',['partyIdClient','quoteId']),

        ...mapState('master',['requiredRule','objGeoCoding'])

    },
    methods: {
        
        ...mapMutations('master',['mapMutations','setUrl']),

        ...mapActions('master',['requestApi']),

        close(){
            this.$emit('close',false)
        },

        getDataAutocomplete(url,input){

            this.setUrl(`geo/${url}`)
            this.requestApi({
                method: 'GET',
                data:{} 
            }).then(res => {
                console.log(res)
                if(input === 'provinces'){
                    this.provinces= res.data.detail
                }else if(input === 'states'){
                    this.states= res.data.detail
                }else{
                    this.sectors = res.data.detail
                    this.autoCompleteSector = res.data.detail.length
                }
            })

        },

        setShippingPoint(){

            /*if(!this.action){
                clearInterval(interval)
                this.saveDirection=false
                let province = this.provinces.find(e => e.geo_id == this.state.provincia_id)
                let sector = this.sectors.find(e => e.id == this.sector)
                let direction = (this.sector == null ? '' : (typeof this.sector =='number' ? sector.nombre : this.sector) )+' '+(this.street == null ? '' : this.street)+' '+(this.street2 == null ? '': this.street2)+' '+this.state.parroquia+' '+province.geo_name

                interval = setTimeout(()=>{
                    this.pointShippment= []
                    this.objGeoCoding.provider.search({query:direction})
                    .then(res => {
                        console.log(res)
                        this.pointShippment.push({
                            latLng:[res[0].y,res[0].x],
                            label:res[0].label
                        })
                        this.$emit('point', this.pointShippment)
                        this.saveDirection=true
                    })
                },1000)
            }*/

        },

        storeDirection(){
           
            if(this.$refs.from_direction.validate()){

                this.setUrl('datos-person')
                let sectorObj = this.sectors.find(e => e.id == this.sector)
                let sectorName = typeof sectorObj !='undefined' ? sectorObj.nombre : ''
                let province = this.provinces.find(e => e.geo_id == this.province)
            
                this.requestApi({
                    method: 'POST',
                    data:{
                        province: this.province,
                        state: this.state,
                        street: this.street,
                        secondaryStreet: this.street2,
                        sector: sectorName,
                        reference: this.reference,
                        partyId: this.partyIdClient,
                        aciton: this.action,
                        quoteId : this.quoteId,
                        pointShippment: this.pointShippment,
                        attnName: this.attnName
                    }
                }).then(res => {
                    this.$emit('storeDirection',{
                        res,
                        direction:`${this.street} ${this.street2} ${sectorName} ${province.geo_name}  ${this.state.parroquia}`,
                        point: this.pointShippment,
                        attnName: this.attnName,
                        action: this.action
                    })
                    this.close()
                })

            }

        },
    
    },
    created(){
        console.log(this.action)
        this.getDataAutocomplete('ECU/provincias-pais','provinces')
    }
}
</script>

<style>

</style>