<template>
    <v-form ref="form_patient">
        <v-col cols=12>
            <v-alert
                    dense
                    icon="mdi-account-settings"
                    type="info"
                >
                    Datos del paciente
            </v-alert>
            <v-row class="mb-3">
                <!--<v-col cols=4>
                    <v-select
                        v-model=identificationTypeId
                        label="Tipo"
                        dense
                        :items=identificationTypes
                        item-value="id"
                        item-text="name"
                        :rules=required
                    ></v-select>
                </v-col>
                <v-col cols=8>
                    <v-text-field
                        v-model=identification
                        label="Identificación"
                        dense
                        type="number"
                        :rules=required
                    ></v-text-field>
                </v-col> -->
                <v-col cols=6>
                    <v-text-field
                        v-model=names
                        label="Nombre"
                        dense
                        :rules=required
                    ></v-text-field>
                </v-col>
                <v-col cols=6>
                    <v-text-field
                        v-model=lastNames
                        label="Apellidos"
                        dense
                        :rules=required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-col class="text-center pt-0">
                <v-btn 
                    color="primary"
                    @click=storePatient
                    small
                > 
                    <v-icon>mdi-content-save</v-icon> Guardar 
                </v-btn>
                <v-btn 
                    color="default"
                    @click=close
                    small
                > 
                    <v-icon>mdi-cancel</v-icon> Cerrar 
                </v-btn>
            </v-col>
        </v-col>
    </v-form>
</template>

<script>
import {mapState, mapActions,mapMutations} from 'vuex'

export default {
    name:'CapturePatient',
    data: () => ({
        identificationTypeId:null,
        partyId:null,
        identification:null,
        names: null,
        lastNames: null,
        identificationTypes:[
            {id:'CEDULA',name:'CÉDULA'},
            {id:'RUC',name:'RUC'},
            {id:'PASAPORTE',name:'PASAPORTE'}
        ],
        required:[v => !!v || 'El campo es requerido'],
    }),
    computed:{

        ...mapState('pos',['quoteId','partyIdClient'])

    },
    methods:{

        ...mapMutations('master',['mapMutations','setUrl']),

        ...mapActions('master',['requestApi']),

        storePatient(){
           
            if(!this.$refs.form_patient.validate())
                return false

            this.setUrl('datos-person')
            this.requestApi({
                method: 'POST',
                data:{
                    names: this.names,
                    lastNames: this.lastNames,
                    partyId: this.partyId,
                    partyIdClient: this.partyIdClient,
                    quoteId : this.quoteId,
                    paciente:true,
                }
            }).then(res => {
                this.$emit('storePatient',{res})
                this.close()
            })

        },

        searchPatient(){

            this.setUrl('datos-person')
            this.requestApi({
                method: 'GET',
                data:{
                    identification: this.identification
                }
            }).then(res => {
                console.log(res)

                this.identificationTypeId= ''
                this.partyId= ''
                this.identification= ''
                this.names= ''
                this.lastNames= ''

                if(res.data.detail != null){
                    const {
                        first_name, identification, last_name,
                        party_identification_type_id, party_id
                    } = res.data.detail

                    this.identificationTypeId= party_identification_type_id,
                    this.partyId= party_id,
                    this.identification= identification,
                    this.names= first_name,
                    this.lastNames= last_name
                }

            })

        },

        close(){
            this.identification= null
            this.names= null
            this.lastNames= null
            this.partyId = null
            this.$emit('close',false)
        }
    }
}
</script>

<style>

</style>