<template>
    <div>
        <v-form ref='form_email'>
            <v-col>
                <v-text-field
                    v-model=email
                    label="Ingrese el correo del cliente"
                    prepend-inner-icon="mdi-email-outline"
                    :rules=requiredRule
                ></v-text-field>
            </v-col>
        </v-form>
        <v-col class="text-center pt-0">
            <v-btn 
                color="primary"
                @click=saveMail
                small
            > 
                <v-icon>mdi-content-save</v-icon> Guardar 
            </v-btn>
            <v-btn 
                color="default"
                @click=close
                small
            > 
                <v-icon>mdi-cancel</v-icon> Cerrar 
            </v-btn>
        </v-col>
    </div>
</template>

<script>
import {mapState, mapActions,mapMutations} from 'vuex'

export default {
    name:'CaptureEmail',
    data: () => ({
        email:null
    }),
    computed:{

        ...mapState('pos',['partyIdClient','quoteId']),

        ...mapState('master',['requiredRule'])

    },
    methods:{

        ...mapMutations('master',['mapMutations','setUrl']),

        ...mapActions('master',['requestApi']),

        saveMail(){

            if(this.$refs.form_email.validate()){
                this.setUrl('datos-person')
                this.requestApi({
                    method: 'POST',
                    data:{
                        email: this.email,
                        partyId: this.partyIdClient,
                        quoteId : this.quoteId
                    }
                }).then(res => {
                    this.$emit('storeEmail',{res:res,email:this.email})
                    this.close()
                })
            }
        
        },

        close(){
            this.phone=null
            this.$emit('close',false)
        }
    }
}
</script>

<style>

</style>